@import '../styles/base';

.projectContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    margin: auto;
}

.projectTitlePage {
    margin: 0;
    padding-top: 6rem;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .projectContainer {
        max-width: 1024px;
    }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
      .projectContainer {
          grid-template-columns: 1fr;
          max-width: 768px;
      }

      .projectTitlePage {
          font-size: 3rem;
      }
}

@media screen and (max-width: 375px) {
    .projectContainer {
        grid-template-columns: 1fr;
    }

    .projectTitlePage {
        font-size: 3rem;
    }
}