@import '../styles/base';

.navbar {
    display: grid;
    min-height: 3rem;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    background: $dark-charcoal;
    color: $linen;
    font-family: $raleway-font;
    position: fixed;
    width: 100%;
}

.icon {
    width: 100%;
}

.navItems {
    display: flex;
    list-style-type: none;
    color:$linen;
    font-weight: bold;
    padding: 0;
}

.navContainerLeft {
    color: $linen;
}

.navContainerRight {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
}

a {
    text-decoration: none;
    color:$linen;
}

.navLinks {
    margin-left: 2rem;
    border: 3px solid transparent;
}

.navLinks:hover {
    border-bottom: 3px solid $linen;
    // line-height: 10px;
    // padding-bottom: 5px;
}

.hidden {
    display: none;
}

.hamburgerTrigger {
    display: none;
}

//MEDIUM DEVICES

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .navContainerLeft,
    .navContainerRight {
        display: none;
    }

    .hamburgerTrigger {
        display: block;
    }
}

//SMALL DEVICES

@media screen and (min-width: 376px) and (max-width: 768px) {
    .navContainerLeft,
    .navContainerRight {
        display: none;
    }
    
    .hamburgerTrigger {
        display: block;
    }
}

// MOBILE AND SMARTPHONE

@media screen and (max-width: 375px) {
    .navContainerLeft,
    .navContainerRight {
        display: none;
    }

    .hamburgerTrigger {
        display: block;
    }
}