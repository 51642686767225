@import '../styles/base';

.contactPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contactTitle {
    font-size: 5rem;
}

.emailContact {
    font-size: 3rem;
    border: 1px solid $dark-charcoal;
    border-radius: 8px;
    color: $dark-charcoal;
    cursor: pointer;
    text-align: center;
    padding: 4px 20px;
    font-weight: bold;
    margin-top: 1%;
    font-family: $branch-font;
}

.emailContact:hover {
    background: $dark-charcoal;
    color: $linen;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .contactTitle {
        font-size: 4rem;
        text-align: center;
    }

    .emailContact {
        font-size: 2rem;
        text-align: center;
    }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
    .contactTitle {
        font-size: 2.75rem;
        text-align: center;
    }

    .emailContact {
        font-size: 2rem;
        text-align: center;
    }
}

@media screen and (max-width: 375px) {
    .contactTitle {
        font-size: 2.5rem;
    }

    .emailContact {
        font-size: 2rem;
        text-align: center;
    }
}