@import '../styles/base';

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}

.content h1 {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    animation:
        typing 3.5s steps(40, end),
        blink-caret .75s step-end;
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: $dark-charcoal;
    }
}

h1 {
    margin: 2%;
    font-size: 5rem;
    text-align: center;
    color: $dark-charcoal;
}

.aboutMe {
    font-family: $raleway-font;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .h1Animation {
        font-size: 4rem;
    }

    .aboutMe {
        text-align: center;
        font-size: 1.5rem;
    }
}


@media screen and (min-width: 376px) and (max-width: 768px) {

.h1Animation {
    font-size: 3rem;
    }

.aboutMe {
    text-align: center;
    }
}


@media screen and (max-width: 375px) {
    .h1Animation {
        font-size: 3rem;
    }

    .aboutMe {
        text-align: center;
    }

}