@import '../styles/base';

.cardContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
}

.projectDetailsCard {
    background: $linen;
    border: 1px solid $dark-charcoal;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-family: $raleway-font;
    height: 100%;
    padding: 1rem;
}

.gitHubLinks {
    display: flex;
    justify-content: space-evenly;
}

.projectImage {
    border-radius: 8px;
    height: 20rem;
    object-fit: cover;
    width: 100%;
}

.projectTitle {
    font-size: 3rem;
}

.projectLinks {
    border: 1px solid $dark-charcoal;
    border-radius: 8px;
    color: $dark-charcoal;
    cursor: pointer;
    font-weight: bold;
    padding: 15px 25px;
}


.description {
    flex-grow: 1;
}

.loginInfo {
    margin-top: 0;
}

.projectLinks:hover {
    background: $dark-charcoal;
    color: $linen;
}

@media screen and (min-width: 769px) and (max-width: 1024px) { 
    .projectTitle {
        font-size: 2.5rem;
    }

    .projectLinks {
        font-size: 0.8rem;
        padding: 12px 18px;
    }

    .projectImage {
        height: 15rem;
    }
}


@media screen and (min-width: 376px) and (max-width: 768px) {
    .cardContainer {
        margin: auto;
        width: 22rem;
    }

     .projectTitle {
         font-size: 2rem;
     }

     .projectLinks {
         font-size: 0.8rem;
         padding: 12px 18px;
     }

     .projectImage {
         height: 15rem;
     }
}

@media screen and (max-width: 375px) {
    .projectTitle {
        font-size: 2rem;
    }

    .projectLinks {
        font-size: 0.8rem;
        padding: 12px 18px;
    }

    .projectImage {
        height: 15rem;
    }
}